import { gql } from '@apollo/client'
import { cartDetailsFragment } from '../fragements/cart'

const getCartQuery = /* GraphQL */ gql`
  query getCart(
    $cartId: ID!
    $country: CountryCode!
    $language: LanguageCode!
  )@inContext(country: $country, language: $language) {
    cart(id:$cartId) {
      ...cartDetails
    }
  }
  ${cartDetailsFragment}
`
export default getCartQuery
