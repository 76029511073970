import { SliderArrowLeft, SliderArrowRight } from '@components/icons'
import cn from 'classnames'
import { useTranslation } from 'next-i18next'
import { FC, MouseEventHandler, memo } from 'react'
import s from './SliderControls.module.css'

interface CollectionsSliderControl {
  onPrev: MouseEventHandler<HTMLButtonElement>
  onNext: MouseEventHandler<HTMLButtonElement>
}

const SliderControls: FC<CollectionsSliderControl> = ({ onPrev, onNext }) => {
  const { t } = useTranslation('common')
  return (
    <>
      <button
        className={cn(s.leftControl, s.control)}
        onClick={onPrev}
        aria-label={`${t('previous-product')}`}
      >
        <SliderArrowLeft />
      </button>
      <button
        className={cn(s.rightControl, s.control)}
        onClick={onNext}
        aria-label={`${t('next-product')}`}
      >
        <SliderArrowRight />
      </button>
    </>
  )
}

export default memo(SliderControls)
