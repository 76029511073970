export const cartDetailsFragment = /* GraphQL */ `
  fragment cartDetails on Cart {
    id
    createdAt
    updatedAt
    checkoutUrl
    totalQuantity
    note
    attributes {
      key
      value
    }
    cost {
      totalAmount {
        amount
        currencyCode
      }
      subtotalAmount {
        amount
        currencyCode
      }
      totalTaxAmount {
        amount
        currencyCode
      }
      totalDutyAmount {
        amount
        currencyCode
      }
    }
    buyerIdentity {
      countryCode
      purchasingCompany{
        company{
          id
          name
        }
        location{
          id
          name
        }
      }
    }
    discountAllocations{
      discountedAmount{
        amount
        currencyCode
      }
    }
    lines(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          quantity
          attributes {
            key
            value
          }
          cost {
            amountPerQuantity {
              amount
              currencyCode
            }
            compareAtAmountPerQuantity {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
            totalAmount {
              amount
              currencyCode
            }
          }
          discountAllocations {
            discountedAmount {
              amount
              currencyCode
            }
          }
          merchandise {
            ... on ProductVariant {
              id
              sku
              title
              availableForSale
              currentlyNotInStock
              requiresShipping
              selectedOptions {
                name
                value
              }
              image {
                url
                altText
                width
                height
              }
              price {
                amount
                currencyCode
              }
              compareAtPrice {
                amount
                currencyCode
              }
              product {
                id
                handle
                title
                tags
                productType
              }
            }
          }
        }
      }
     
    }
  }
`
