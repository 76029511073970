const SliderArrowRight = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    { ...props }
  >
    <path
      d="M11.6948 19.8391L11.4413 19.5856C11.2735 19.4178 11.2735 19.1465 11.4413 18.9786L17.8146 12.609L4.3933 12.609C4.15765 12.609 3.96484 12.4162 3.96484 12.1805V11.8235C3.96484 11.5879 4.15765 11.3951 4.3933 11.3951L17.8146 11.3951L11.4413 5.02183C11.2735 4.85402 11.2735 4.58267 11.4413 4.41486L11.6948 4.16135C11.8626 3.99354 12.134 3.99354 12.3018 4.16135L19.839 11.695C20.0068 11.8628 20.0068 12.1341 19.839 12.3019L12.3018 19.8356C12.1304 20.0069 11.8626 20.0069 11.6948 19.8391Z"
      fill="#646464"
    />
  </svg>
)

export default SliderArrowRight
