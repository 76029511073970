import { getListConfig } from "./getListConfig"

export const getUnAuthEmailFromStorage = (locale: string) => {
  const listUnauthEmailStorageName = getListConfig(locale)?.listUnauthEmailStorageName
  return localStorage.getItem(listUnauthEmailStorageName)
}

export const fetcher = async (
  input: RequestInfo,
  init: RequestInit,
  ...args: any[]
) => {
  const res = await fetch(input, init)
  return res.json()
}

export const swrOptions = {
  revalidateOnFocus: false,
}
