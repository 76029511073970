'use client'

import { useQuery, useReactiveVar } from '@apollo/client'
import { customerAccessTokenVar } from '@commerce/client'
import { ShopifyCustomer } from '@commerce/types/customer'
import { getCustomerQuery } from '@commerce/utils'

import { useRouter } from 'next/router'
export default function useCustomer() {
  const { locale } = useRouter()
  const customerAccessToken = useReactiveVar(customerAccessTokenVar)
  const { data: customerData, loading } = useQuery(getCustomerQuery, {
    context: {
      locale,
      customerAccessToken: customerAccessToken,
      useCustomerApi: true,
    },
    skip: !(customerAccessToken && customerAccessToken.length > 0),
  })
  const customer: ShopifyCustomer = customerData?.customer
  const customerCompanyLocationId =
    customer?.companyContacts?.edges?.[0]?.node?.company?.locations?.edges?.[0]
      ?.node?.id
  const isB2B = customerCompanyLocationId ? true : false
  return { customer, loading, isB2B, customerCompanyLocationId }
}
