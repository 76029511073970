import useSWR from 'swr';
import { fetcher, swrOptions } from '../utils/helper';

export default function useGetLists(email: string, locale?: string) {
  const { data, isValidating } = useSWR(
    email ? `/api/list?email=${email}&locale=${locale}&multipleList=true` : null,
    fetcher,
    swrOptions
  );
  return { data: data?.lists || [], loading: isValidating };
}