const Check = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.74683 15.7286L2.62204 11.6039C2.45932 11.4411 2.45932 11.1773 2.62204 11.0146C2.78476 10.8519 3.04858 10.8519 3.21129 11.0146L7.04146 14.8448L16.7642 5.12204C16.9269 4.95932 17.1907 4.95932 17.3534 5.12204C17.5161 5.28476 17.5161 5.54858 17.3534 5.71129L7.33608 15.7286C7.17337 15.8914 6.90955 15.8914 6.74683 15.7286Z"
        fill="#646464"
      />
    </svg>
  )
}

export default Check
