import WishlistButton from '@components/arkade/list/WishlistButton'
import { useUI } from '@components/ui'
import config from '@config/seo_meta.json'
import cn from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { Fragment } from 'react'
import QuickAddButton from '../QuickAddButton'

export enum ProductCardMode {
  COLOUR,
  IMAGE,
}

const ProductCard = ({
  id,
  handle,
  name,
  imageUrl,
  imageAlt,
  colourCode,
  mode,
  classNames,
  type,
  popular,
  variant,
  showQuickAddButton,
  showWishListButton = false,
  colourCardHandle,
  path,
  smallestCard = false,
  searchHit = false,
  hit,
  sendEvent,
}: {
  id: string
  handle?: string
  name: string
  imageUrl?: string
  imageAlt?: string
  colourCode?: string | null
  mode: ProductCardMode
  classNames?: string
  type?: string
  popular: boolean
  variant?: 'large' | 'regular'
  showQuickAddButton?: boolean
  showWishListButton?: boolean
  colourCardHandle?: string
  path?: string
  smallestCard?: boolean
  searchHit?: boolean
  hit?: any
  sendEvent?: any
}) => {
  const { closeSidebarIfPresent } = useUI()
  const showColour = mode === ProductCardMode.COLOUR
  const isColourCard =
    type?.toLowerCase() === 'colour' || type?.toLowerCase() === 'farbe'

  const handleClick = () => {
    hit && sendEvent && sendEvent('click', hit, 'Product Clicked')
    closeSidebarIfPresent()
  }
  if (!handle) return <></>
  const product = { handle, type, colourCode }
  return (
    <Fragment key={`product-card-${id}`}>
      <Link href={`/product/${handle}`} passHref>
        {/* keen-slider__slide */}
        <div
          className={cn(
            'block',
            { 'colourProductCard ': isColourCard },
            { colour: isColourCard && showColour },
            { image: isColourCard && !showColour },
            classNames
          )}
          onClick={handleClick}
        >
          <div
            className={cn('relative', {
              imageContainer: isColourCard,
            })}
          >
            <div
              className={cn('relative', {
                'rectangle aspect-4/5': variant !== 'large',
                'rectangle aspect-[32/41]': variant === 'large',
              })}
            >
              <Image
                src={imageUrl || '/product-img-placeholder.svg'}
                alt={imageAlt || config.openGraph.images[0].alt}
                fill
                style={{ objectFit: 'cover' }}
                sizes={
                  variant === 'large'
                    ? '50vw'
                    : '(min-width: 1024px) 25vw, 50vw'
                }
                placeholder="blur"
                blurDataURL={imageUrl || '/product-img-placeholder.svg'}
              />
            </div>
            {popular && (
              <div className=" body-3-bold absolute top-0 z-10 mt-2 ml-2 rounded-lg bg-ui-dark-grey px-2 uppercase text-brand-light-grey md:mt-4 md:ml-4 md:px-4">
                Popular
              </div>
            )}
            <div className="absolute top-2 right-2 z-10">
              <QuickAddButton
                hide={!showQuickAddButton}
                quickAddProductHandle={colourCardHandle!}
                parentProduct={product}
                smallestCard={smallestCard}
              />
            </div>
            {showWishListButton && isColourCard && (
              <div className="absolute top-[52px] right-2 z-10">
                <WishlistButton
                  product={{
                    id: id,
                    name: name,
                    slug: handle,
                    path: path,
                    images: [
                      {
                        url: imageUrl || '/product-img-placeholder.svg',
                        altText: imageAlt || config.openGraph.images[0].alt,
                      },
                    ],
                    colourCardHandle: colourCardHandle,
                    productType: type,
                    handle: handle,
                    colourCode: colourCode,
                  }}
                  smallestCard={smallestCard}
                  listId={null}
                />
              </div>
            )}
          </div>

          <h3
            className={cn(
              `pt-2 text-brand-dark-grey ${
                searchHit ? 'label lg:pt-2' : 'body1 lg:pt-3'
              }`
            )}
          >
            {name}
          </h3>
        </div>
      </Link>
      <style jsx>
        {`
          .imageContainer {
            line-height: 0;
          }
          .imageContainer::before {
            content: '';
            position: absolute;
            background-color: ${colourCode || '#fff'};
            width: 100%;
            height: 100%;
            z-index: ${showColour ? 1 : 0};
          }
          .colourProductCard:hover .imageContainer::before {
            z-index: ${showColour ? 0 : 1};
          }

          .square:after {
            content: '';
            display: block;
            padding-bottom: 100%;
          }

          @media (min-width: 1024px) {
            .rectangle:after {
              content: '';
              display: block;
              padding-bottom: calc(100% + 3rem);
            }
          }

          @media (max-width: 1023px) {
            .rectangle:after {
              content: '';
              display: block;
              padding-bottom: calc(100%);
            }
          }
        `}
      </style>
      {/* if global mouse hover effect causes issue then we need to create
        unique class name per item
        */}
      <style jsx global>
        {`
          .colourProductCard.colour .imageContainer img {
            transition: all 0.5s ease;
            opacity: 0;
          }

          .colourProductCard.image .imageContainer img {
            transition: all 0.5s ease;
            opacity: 100;
          }

          .colourProductCard.colour:hover .imageContainer img {
            opacity: 100;
          }

          .colourProductCard.image:hover .imageContainer img {
            opacity: 0;
          }
        `}
      </style>
    </Fragment>
  )
}

export default ProductCard
