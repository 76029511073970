import { Product } from '@commerce/types'
import SliderControls from '@components/common/SliderControls'
import { ColourAction, ImageAction } from '@components/icons'
import ProductCard, {
  ProductCardMode,
} from '@components/product/ProductCard/ProductCard'
import {
  COLOUR_CARD_METAFIELD_KEY,
  findMetafieldValueByKey,
} from '@lib/helpers/general'
import { useKeenSlider } from 'keen-slider/react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
// import sampleProducts from './products.json'

const ProductSlider = ({ slice, context }: any) => {
  const { locale } = useRouter()
  const { t } = useTranslation('common')
  const [mode, setMode] = useState<ProductCardMode>(ProductCardMode.COLOUR)

  const collection: any = context?.collectionProducts?.filter(
    (item: any) => item.handle === slice?.primary?.shopifyCollectionHandle
  )
  const products: Product.Product[] = collection?.[0]?.products

  // let products = sampleProducts
  if (!products || products?.length === 0) <></>

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    // initial: 0,
    loop: true,
    slides: {
      perView: 1.2,
      spacing: 16,
      origin: 'center',
    },
    breakpoints: {
      '(min-width: 600px)': {
        slides: {
          perView: 4.2,
          spacing: 16,
          origin: 'auto',
        },
      },
      '(min-width: 1024px)': {
        slides: {
          perView: 4.2,
          //origin: 'center',
          spacing: 32,
          origin: 'auto',
        },
      },
    },
  })

  useEffect(() => {
    slider.current?.update()
  }, [products, slider])

  const onPrev = (e: any) => e.stopPropagation() || slider.current?.prev()
  const onNext = (e: any) => e.stopPropagation() || slider.current?.next()

  const setSliderModeColour = () => setMode(ProductCardMode.COLOUR)
  const setSliderModeImage = () => setMode(ProductCardMode.IMAGE)

  const bgWhite = slice?.primary?.backgroundColourWhite
  return (
    <section className={bgWhite ? 'bg-white' : ''} key={`slider-${locale}`}>
      <div className="md:wrapper flex flex-col pt-6 pb-10 lg:pt-14  lg:pb-15">
        <div className="heading-mobile-3 lg:heading-3 flex items-center gap-4 px-4 pb-8 text-brand-dark-grey md:px-0 lg:pb-10">
          {slice?.primary?.title && <span>{slice?.primary?.title}</span>}
          <button type="button" onClick={setSliderModeColour}>
            <ColourAction opacity={mode === ProductCardMode.COLOUR ? 1 : 0.5} />
          </button>
          <button type="button" onClick={setSliderModeImage}>
            <ImageAction opacity={mode === ProductCardMode.IMAGE ? 1 : 0.5} />
          </button>
          <button className="body-1 ml-auto hidden underline md:block">
            <Link passHref href="/collection/colour-lab">
              {t('shop-all-colours')}
            </Link>
          </button>
        </div>

        <div ref={sliderRef} className="keen-slider">
          {products?.map((product: Product.Product, index: number) => {
            const colourCardHandle = findMetafieldValueByKey(
              product?.metafields!,
              COLOUR_CARD_METAFIELD_KEY
            )
            return (
              <ProductCard
                key={`slider-${index}`}
                id={product.id}
                mode={mode}
                path={product.path}
                handle={product.handle}
                name={product.name}
                colourCode={product?.colourCode}
                imageUrl={product?.images[0]?.url}
                imageAlt={product?.images[0]?.alt}
                type={product.productType}
                popular={product.popular}
                classNames="keen-slider__slide"
                showQuickAddButton
                showWishListButton
                colourCardHandle={colourCardHandle!}
              />
            )
          })}
          {slider && <SliderControls onPrev={onPrev} onNext={onNext} />}
        </div>
        <button className="body-1 mt-8 flex items-center justify-center underline md:hidden">
          <Link passHref href="/collection/colour-lab">
            {t('shop-all-colours')}
          </Link>
        </button>
      </div>
    </section>
  )
}

export default ProductSlider
