interface Props {
  fill?: string
  stroke?: 'white' | 'grey'
}
const Heart = ({ fill, stroke }: Props) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {stroke == 'white' ? (
          <path
            d="M20.0592 4.44914C17.9303 2.63663 14.7467 2.93741 12.7701 4.97649L12.0006 5.77337L11.231 4.9804C9.63727 3.33195 6.4224 2.33975 3.9419 4.44914C1.48876 6.54291 1.35985 10.3008 3.55518 12.5664L11.1138 20.3712C11.356 20.6212 11.6764 20.7501 11.9967 20.7501C12.317 20.7501 12.6373 20.6251 12.8795 20.3712L20.4382 12.5664C22.6413 10.3008 22.5124 6.54291 20.0592 4.44914ZM19.5475 11.6992L12.0162 19.504L4.45363 11.6992C2.95361 10.1523 2.64111 7.20307 4.75441 5.40228C6.89506 3.57414 9.41071 4.89837 10.3326 5.8515L12.0006 7.57417L13.6686 5.8515C14.5748 4.91399 17.1139 3.58585 19.2467 5.40228C21.3561 7.19917 21.0475 10.1484 19.5475 11.6992Z"
            fill={fill || '#646464'}
          />
        ) : (
          <path
            d="M20.0592 4.44914C17.9303 2.63663 14.7467 2.93741 12.7701 4.97649L12.0006 5.77337L11.231 4.9804C9.63727 3.33195 6.4224 2.33975 3.9419 4.44914C1.48876 6.54291 1.35985 10.3008 3.55518 12.5664L11.1138 20.3712C11.356 20.6212 11.6764 20.7501 11.9967 20.7501C12.317 20.7501 12.6373 20.6251 12.8795 20.3712L20.4382 12.5664C22.6413 10.3008 22.5124 6.54291 20.0592 4.44914Z"
            fill={fill || '#646464'}
          />
        )}
      </svg>
    </>
  )
}

export default Heart
