const listConfig = {
  context: {
    'en-au': {
      listAPIUrl: process.env.ARKADE_LIST_API_URL,
      listTenantID: process.env.ARKADE_LIST_TENANT_ID_AU,
      listTempStorageName: 'tempListId_AU',
      listUnauthEmailStorageName: 'unAuthEmail_AU',
      tempListStorageName: 'tempList_AU',
    },
    'en-us': {
      listAPIUrl: process.env.ARKADE_LIST_API_URL,
      listTenantID: process.env.ARKADE_LIST_TENANT_ID_US,
      listTempStorageName: 'tempListId_US',
      listUnauthEmailStorageName: 'unAuthEmail_US',
      tempListStorageName: 'tempList_US',
    },
    'es-us': {
      listAPIUrl: process.env.ARKADE_LIST_API_URL,
      listTenantID: process.env.ARKADE_LIST_TENANT_ID_US,
      listTempStorageName: 'tempListId_ES_US',
      listUnauthEmailStorageName: 'unAuthEmail_ES_US',
      tempListStorageName: 'tempList_ES_US',
    },
    'en-eu': {
      listAPIUrl: process.env.ARKADE_LIST_API_URL,
      listTenantID: process.env.ARKADE_LIST_TENANT_ID_EU,
      listTempStorageName: 'tempListId_EU',
      listUnauthEmailStorageName: 'unAuthEmail_EU',
      tempListStorageName: 'tempList_EU',
    },
    'de-eu': {
      listAPIUrl: process.env.ARKADE_LIST_API_URL,
      listTenantID: process.env.ARKADE_LIST_TENANT_ID_EU,
      listTempStorageName: 'tempListId_DE',
      listUnauthEmailStorageName: 'unAuthEmail_DE',
      tempListStorageName: 'tempList_DE',
    },
    'es-eu': {
      listAPIUrl: process.env.ARKADE_LIST_API_URL,
      listTenantID: process.env.ARKADE_LIST_TENANT_ID_EU,
      listTempStorageName: 'tempListId_ES_EU',
      listUnauthEmailStorageName: 'unAuthEmail_ES_EU',
      tempListStorageName: 'tempList_ES_EU',
    },
    'en-gb': {
      listAPIUrl: process.env.ARKADE_LIST_API_URL,
      listTenantID: process.env.ARKADE_LIST_TENANT_ID_UK,
      listTempStorageName: 'tempListId_GB',
      listUnauthEmailStorageName: 'unAuthEmail_GB',
      tempListStorageName: 'tempList_GB',
    },
  },
}

module.exports = { listConfig }
