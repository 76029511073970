const ColourAction = ({ ...props }) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.375 18.188a.562.562 0 1 0 0-1.125.562.562 0 0 0 0 1.125Zm13.5-3.938h-5.352l3.785-3.785c.44-.439.44-1.151 0-1.59l-3.183-3.182a1.121 1.121 0 0 0-1.59 0L9.75 9.477V4.125C9.75 3.504 9.246 3 8.625 3h-4.5C3.504 3 3 3.504 3 4.125v13.5A3.375 3.375 0 0 0 6.375 21h13.5c.621 0 1.125-.504 1.125-1.125v-4.5c0-.621-.504-1.125-1.125-1.125Zm-11.25 3.375c0 1.24-1.01 2.25-2.25 2.25s-2.25-1.01-2.25-2.25v-4.5h4.5v4.5Zm0-5.625h-4.5V8.625h4.5V12Zm0-4.5h-4.5V4.125h4.5V7.5Zm1.125 3.567 4.579-4.579 3.183 3.182-7.762 7.762v-6.365Zm10.125 8.808H8.898l4.5-4.5h6.477v4.5Z"
      fill={props?.fill || '#646464'}
      opacity={props?.opacity || 1}
    />
  </svg>
)

export default ColourAction
