import useSWR from 'swr';
import { fetcher, swrOptions } from '../utils/helper';

export default function useGetList(
  listId: string,
  locale?: string
) {
  const { data, isValidating } = useSWR(
    listId ? `/api/list/${listId}?locale=${locale}` : null,
    fetcher,
    swrOptions
  )
  return { data: data?.list || {}, loading: isValidating };
}
