import commerceConfig from '@commerce/config'
import processLocale from '@lib/locale'
import Cookies from 'js-cookie'

const getCartId = (locale: string | undefined) => {
  const {
    config: { context },
  } = commerceConfig
  const currentLocale = processLocale(locale)
  const currentContext = context?.[currentLocale]

  return Cookies.get(currentContext.cartIdCookieName)
}

export default getCartId
