const SliderArrowLeft = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.2349 19.8391L12.4884 19.5856C12.6562 19.4178 12.6562 19.1465 12.4884 18.9786L6.11514 12.609L19.5364 12.609C19.772 12.609 19.9648 12.4162 19.9648 12.1805V11.8235C19.9648 11.5879 19.772 11.3951 19.5364 11.3951L6.11514 11.3951L12.4884 5.02183C12.6562 4.85402 12.6562 4.58267 12.4884 4.41486L12.2349 4.16135C12.067 3.99354 11.7957 3.99354 11.6279 4.16135L4.0907 11.695C3.92289 11.8628 3.92289 12.1341 4.0907 12.3019L11.6279 19.8356C11.7993 20.0069 12.067 20.0069 12.2349 19.8391Z"
      fill="#646464"
    />
  </svg>
)

export default SliderArrowLeft
