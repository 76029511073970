import { gql } from '@apollo/client'
export const getCustomerQuery = /* GraphQL */ gql`
  query getCustomerForSession {
    customer {
      id
      firstName
      lastName
      displayName
      emailAddress {
        emailAddress
        marketingState
      }
      phoneNumber {
        phoneNumber
        marketingState
      }
      defaultAddress {
        address1
        address2
        city
        company
        country
        zip
        province
        phoneNumber
        id
        zoneCode
        territoryCode
      }
      tags
      metafields(
        identifiers: [
          { namespace: "custom", key: "vat_number" }
          { namespace: "custom", key: "eori_number" }
          { namespace: "custom", key: "employer_id" }
        ]
      ) {
        namespace
        key
        value
        type
        id
      }
      companyContacts(first: 1) {
        edges {
          node {
            id
            company {
              id
              name
              locations(first: 1) {
                edges {
                  node {
                    id
                    name
                    shippingAddress {
                      address1
                      address2
                      city
                      companyName
                      country
                      countryCode
                      id
                      phone
                      province
                      zip
                      zoneCode
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export default getCustomerQuery
