const ImageAction = ({ ...props }) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.5 9.429a1.929 1.929 0 1 0 0-3.858 1.929 1.929 0 0 0 0 3.858Zm0-2.572a.643.643 0 1 1 0 1.286.643.643 0 0 1 0-1.286ZM19.714 3H4.286A1.286 1.286 0 0 0 3 4.286v15.428A1.286 1.286 0 0 0 4.286 21h15.428A1.286 1.286 0 0 0 21 19.714V4.286A1.286 1.286 0 0 0 19.714 3Zm0 16.714H4.286V16.5h15.428v3.214Zm-13.29-4.5 2.088-2.785 1.608 2.142-.482.643H6.422Zm4.82 0 3.054-4.071 3.054 4.071h-6.107Zm8.47 0h-.755l-4.146-5.528c-.244-.324-.786-.324-1.03 0l-2.86 3.813-1.896-2.527c-.244-.324-.786-.324-1.03 0l-3.182 4.242h-.53V4.286h15.43v10.928Z"
      fill={props?.fill || '#646464'}
      opacity={props?.opacity || 1}
    />
  </svg>
)

export default ImageAction
